import React from 'react';
import safleSmallLogo from "../assets/safle-logo-mobile.svg";

const RotatingGradientWithLogo = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      {/* Rotating Circular Gradient */}
      <div className="relative flex justify-center items-center w-[125px] h-[125px] rounded-full bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 animate-spin-slow z-10">
      <div className='bg-white dark:bg-[#101113] rounded-full w-[123px] h-[123px] mt-[1px] ml-[1px] absolute inset-0 flex items-center justify-center z-20'></div>
      </div>
      
      <div className="absolute inset-0 flex items-center justify-center z-30">
          <img
            src={safleSmallLogo} // Replace with your logo URL
            alt="Loading"
            className="w-20 h-20"
          />
        </div>
    </div>
  );
};

export default RotatingGradientWithLogo;
