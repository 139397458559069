import React from "react";
import Spaceship from "../assets/Icons/BG_MOBILE.svg";
export const ComingSoonMobileVersion = () => {
  return (
    // <div>
    //   <img
    //     src={Spaceship}
    //     alt="Spaceship"
    //     style={{ width: "100%", height: "100vh" }}
    //   />
    // </div>
    <div
      className="flex text-white bg-black flex-col w-full justify-center min-h-screen"
      style={{
        backgroundImage: `url(${Spaceship})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></div>
  );
};
